import React from 'react'
import { Location } from 'react-router-dom'
import { useLocation } from 'react-router-dom';

import { particlesEngine } from './ParticlesBackground.ts';

import './Hello.css'

export function Hello(): React.JSX.Element {

	let currentLocation : Location = useLocation()

	React.useEffect( (): void => {
		particlesEngine.useEffect()
	}, [] );

	React.useEffect( (): void => {
		particlesEngine.onPageLoaded()
	}, [ currentLocation ] );

	return (
		<div className="hello fade-in-0">
			<div className="particlesBackground" data-particles data-particles-friction="0.1" data-particles-spawnrate="1.0" data-particles-life="60.0" data-particles-distance="150" data-particles-particlecolor="rgba(128, 128, 128, 0.4)" data-particles-linecolor="rgba(119, 126, 128, 0.4)" />
			<div className="image-portrait" >
				<div className="text">
					<div className="pageTitle">
						<span className="title text-shadow-3">Hello!<br />I'm <span className="name">Clément Gerber.</span></span><br />
						<span className="description text-shadow-2" >3D Developer & Data Scientist</span>
					</div>
					<div className="bio">I'm passionated by computer science since the age of 12, learning web developpement from the known site <b>openclassroom.com</b> and started developping my own projects.<br />I continued my studies learning developpement in the University of <b>Strasbourg</b>. <br />I worked with many languages and technologies from the web developpement to the realtime graphics on <b>OpenGL</b> and <b>Unreal Engine</b> passing to neural networks with <b>PyTorch</b>.</div>
				</div>
				<div className="logos">
					<div className="logo-Blender">Blender</div>
					<div className="logo-Unreal">Unreal</div>
					<div className="logo-Substance">Substance</div>
					<div className="spacer"></div>
					<div className="logo-Code">Code</div>
					<div className="logo-PyTorch">PyTorch</div>
				</div>
			</div>
		</div>
	);
}