import { Vec2 } from './Vec2.ts'
import { Color } from './Color.ts'

///@brief   Base class for a particle.
class Particle {

    ///@brief   Position of the particle.
    p: Vec2

    ///@brief   Velocity of the particle
    v: Vec2

    ///@brief   Initial position of the particle.
    initP: Vec2

    ///@brief   Life of the particle in seconds.
    life: number

    constructor( x: number, y: number ) {
        this.p = new Vec2( x, y )
        this.initP = new Vec2( x, y )

        this.v = new Vec2( 0.0, 0.0 )
        this.life = 1.0
    }

    public setVelocity( acceleration: Vec2 ): void {
        this.v = acceleration
    }

    ///@brief   Draw the particle on the canvas.
    public draw( ctx: CanvasRenderingContext2D ): void {

    }

}

///@brief   A circle particle.
class ParticleCircle extends Particle {

    private radius: number

    constructor( x: number, y: number, radius: number ) {
        super( x, y )

        this.radius = radius
    }

    public draw( ctx: CanvasRenderingContext2D ): void {
        ctx.arc( this.p.x, this.p.y, this.radius, 0, 2 * Math.PI )
    }

}

///@brief   Main class representing a canvas with particles.
class ParticleBackground {
    private cancasSize: Vec2

    private element: HTMLElement
    private canvas: HTMLCanvasElement
    private ctx: CanvasRenderingContext2D | null
    private particles: Particle[]
    private mousePosition: Vec2
    private dpr: number

    private bMouseDown: boolean
    private bMouseOver: boolean
    private lastFrameTime: DOMHighResTimeStamp
    private surfaceMult: number
    private bShouldStop: boolean

    ///@brief   Friction applied to the particles each frame.
    private friction: number
    ///@brief   The initial velocity of the particles.
    private initialVelocity: number
    ///@brief   The rate at which particles are spawned per seconds.
    private particleSpawnRate: number
    ///@brief   The life of the particles in seconds.
    private particleLife: number

    private particlesColor: Color
    private linesColor: Color
    private linesDistance2: number

    constructor( element: HTMLElement ) {
        this.bMouseDown = false
        this.element = element

        this.dpr = window.devicePixelRatio || 1
        this.particles = []
        this.cancasSize = new Vec2( 0, 0 )
        this.mousePosition = new Vec2( 0, 0 )

        this.constructContext()
        this.initProperties()
    }

    private initProperties(): void {
        this.setFriction( parseFloat( this.element.getAttribute( 'data-particles-friction' ) || '0.1' ) )
        this.setSpawnRate( parseFloat( this.element.getAttribute( 'data-particles-spawnrate' ) || '1.0' ) )
        this.setParticlesLife( parseFloat( this.element.getAttribute( 'data-particles-life' ) || '60.0' ) )
        this.setInitialVelocity( parseFloat( this.element.getAttribute( 'data-particles-initial-velocity' ) || '30.0' ) )
        this.setLinesDistance( parseFloat( this.element.getAttribute( 'data-particles-distance' ) || '100.0' ) )

        let particleColorStr: string | null = this.element.getAttribute( 'data-particles-particlecolor' )
        if ( particleColorStr !== null ) {
            this.setParticlesColor( Color.fromString( particleColorStr ) || new Color( 128, 128, 128, 0.4 ) )
        } else {
            this.setParticlesColor( new Color( 128, 128, 128, 0.4 ) )
        }

        let lineColorStr: string | null = this.element.getAttribute( 'data-particles-linecolor' )
        if ( lineColorStr !== null ) {
            this.setLinesColor( this.linesColor = Color.fromString( lineColorStr ) || new Color( 128, 128, 128, 0.4 ) )
        } else {
            this.setLinesColor( new Color( 128, 128, 128, 0.4 ) )
        }
    }

    private constructContext(): void {
        // Start to clean up the element.
        while ( this.element.firstChild ) {
            this.element.removeChild( this.element.firstChild )
        }

        this.canvas = document.createElement( 'canvas' )
        this.ctx = this.canvas.getContext( '2d' )

        if ( this.ctx === null ) {
            console.log( 'Unable to get 2d context' )
            return
        }

        this.element.appendChild( this.canvas )

        window.removeEventListener( 'resize', this )
        window.addEventListener( 'resize', this )

        window.removeEventListener( 'scroll', this )
        window.addEventListener( 'scroll', this )

        window.removeEventListener( 'mousemove', this )
        window.addEventListener( 'mousemove', this )

        window.removeEventListener( 'mousedown', this )
        window.addEventListener( 'mousedown', this )

        window.removeEventListener( 'mouseup', this )
        window.addEventListener( 'mouseup', this )

        window.removeEventListener( 'mouseenter', this )
        window.addEventListener( 'mouseenter', this )

        window.removeEventListener( 'mouseleave', this )
        window.addEventListener( 'mouseleave', this )

        this.updateSize()
    }

    private updateSize(): void {

        this.cancasSize.x = this.element.clientWidth
        this.cancasSize.y = this.element.clientHeight

        this.surfaceMult = this.cancasSize.x * this.cancasSize.y * this.dpr * this.dpr / ( 1920.0 * 1080.0 )

        this.canvas.width = this.cancasSize.x * this.dpr
        this.canvas.height = this.cancasSize.y * this.dpr

        this.canvas.style.width = this.cancasSize.x + 'px';
        this.canvas.style.height = this.cancasSize.y + 'px';

        if ( this.ctx !== null ) {
            this.ctx.scale( this.dpr, this.dpr );
        }

    }

    getElement(): HTMLElement {
        return this.element
    }

    setParticlesColor( color: Color ): void {
        this.particlesColor = color
    }

    setLinesColor( color: Color ): void {
        this.linesColor = color
    }

    setFriction( friction: number ): void {
        this.friction = friction
    }

    setInitialVelocity( velocity: number ): void {
        this.initialVelocity = velocity
    }

    setSpawnRate( rate: number ): void {
        this.particleSpawnRate = rate
    }

    setParticlesLife( life: number ): void {
        this.particleLife = life
    }

    setLinesDistance( distance: number ): void {
        this.linesDistance2 = distance * distance
    }

    private onMouseEnter( e: Event ): void {
        this.bMouseOver = true
    }

    private onMouseLeave( e: Event ): void {
        this.bMouseOver = false
    }

    private onScroll( e: Event ): void {

    }

    private onResize(): void {
        this.updateSize()
    }

    private onMouseMove( e: MouseEvent ): void {
        this.bMouseOver = true
        this.mousePosition.x = e.clientX - this.element.offsetLeft + document.documentElement.scrollLeft
        this.mousePosition.y = e.clientY - this.element.offsetTop + document.documentElement.scrollTop
    }

    private onMousedown( e: MouseEvent ): void {
        this.bMouseDown = true
    }

    private onMouseup( e: MouseEvent ): void {
        this.bMouseDown = false
    }

    handleEvent( e: Event ): void {
        switch ( e.type ) {
            case 'resize':
                this.onResize()
                break
            case 'mousemove':
                this.onMouseMove( e as MouseEvent )
                break
            case 'mousedown':
                this.onMousedown( e as MouseEvent )
                break
            case 'mouseup':
                this.onMouseup( e as MouseEvent )
                break
            case 'mouseenter':
                this.onMouseEnter( e )
                break
            case 'mouseleave':
                this.onMouseLeave( e )
                break
        }
    }

    private getRandom( min: number, max: number ): number {
        return Math.random() * ( max - min ) + min
    }

    spawnParticles( nb: number, rMin: number = 2.0, rMax: number = 3.0, aMin: number = -30.0, aMax: number = 30.0, lifeMin: number = 30.0, lifeMax: number = 60.0 ): void {
        for ( let i: number = 0; i < nb; i++ ) {
            let x: number = Math.random() * this.canvas.width
            let y: number = Math.random() * this.canvas.height

            let r: number = this.getRandom( rMin, rMax )

            let p: Particle = new ParticleCircle( x, y, r )

            let ax: number = this.getRandom( aMin, aMax )
            let ay: number = this.getRandom( aMin, aMax )

            p.v = new Vec2( ax, ay )

            p.life = this.getRandom( lifeMin, lifeMax )

            this.particles.push( p )
        }

    }

    private initParticles(): void {

        this.particles = []

        // spawn particles randomly
        let nbParticles: number = this.particleSpawnRate * this.surfaceMult * this.particleLife 
        this.spawnParticles( nbParticles, 2.0, 3.0, -this.initialVelocity, this.initialVelocity, this.particleLife * 0.75, this.particleLife * 1.25 )

        this.draw()
    }

    private updateParticles( t: number ): void {

        // Spawn new particles randomly.
        {
            let nbSpawn: number = this.particleSpawnRate * this.surfaceMult * t

            if ( nbSpawn < 1.0 ) {
                nbSpawn = Math.random() < nbSpawn ? 1 : 0
            }

            // Spawn random particles
            this.spawnParticles( nbSpawn, 2.0, 3.0, -this.initialVelocity, this.initialVelocity, this.particleLife, this.particleLife )
        }

        // Update the acceleration of the particles based on the mouse position.
        if ( this.bMouseDown ) {
            let particlesMouseEffectIntensity: number = -50.0

            this.particles.forEach( ( p: Particle ): void => {
                let v: Vec2 = new Vec2( p.p.x - this.mousePosition.x, p.p.y - this.mousePosition.y )
                let d: number = Math.max( Math.sqrt( v.x * v.x + v.y * v.y ), 1.0 )

                if ( d < 512.0 ) {
                    p.v = p.v.add( v.div( d * t * particlesMouseEffectIntensity ) )
                }
            } )
        }

        // Update the position using the velocity.
        this.particles.forEach( ( p: Particle ): void => {
            p.p.x += p.v.x * t
            p.p.y += p.v.y * t

            p.v = p.v.mult( 1.0 - this.friction * t )

            p.life -= t
        } )

        this.particles = this.particles.filter( ( p: Particle ): boolean => { return p.life > 0.0 } )
    }



    private drawLine( p1: Vec2, p2: Vec2, color: string ): void {
        if ( this.ctx === null ) {
            return
        }

        this.ctx.strokeStyle = color
        this.ctx.beginPath()
        this.ctx.moveTo( p1.x, p1.y )
        this.ctx.lineTo( p2.x, p2.y )
        this.ctx.stroke()
    }

    private drawlines( maxLines: number, d2: number ): void {
        if ( this.ctx === null ) {
            return
        }

        let c: Color = new Color( this.linesColor.r, this.linesColor.g, this.linesColor.b, 0.0 )

        for ( let j: number = 0; j < this.particles.length; j++ ) {
            let p: Particle = this.particles[ j ]

            let mouseV: Vec2 = new Vec2( p.p.x - this.mousePosition.x, p.p.y - this.mousePosition.y )
            let mouseD: number = mouseV.x * mouseV.x + mouseV.y * mouseV.y

            if ( mouseD < d2 ) {
                c.a = Math.min( 1.0, ( 1.0 - mouseD / d2 ) * this.linesColor.a )
                this.drawLine( p.p, this.mousePosition, c.toStringHex() )
            }

            let nblines: number = 0

            for ( let i: number = 0; i < this.particles.length; i++ ) {
                let p2: Particle = this.particles[ i ]

                let v: Vec2 = new Vec2( p2.p.x - p.p.x, p2.p.y - p.p.y )
                let d: number = v.x * v.x + v.y * v.y

                if ( d < d2 ) {
                    c.a = Math.min( 1.0, ( 1.0 - d / d2 ) * this.linesColor.a )
                    this.drawLine( p.p, p2.p, c.toStringHex() )
                    nblines++

                    if ( nblines > maxLines ) {
                        break
                    }
                }
            }

        }
    }

    animate( timestamp: number ): void {
        if ( this.bShouldStop ) {
            return
        }

        let timeBeginFrame: number = performance.now()
        let elapsedTimeMS: number = timeBeginFrame - this.lastFrameTime
        let t: number = elapsedTimeMS * 0.001

        if ( this.particles.length > 0 ) {
            this.updateParticles( t )
            this.draw()
        }

        let timeEndFrame: number = performance.now()
        this.lastFrameTime = timeEndFrame
        // console.log( `Frame time: ${timeEndFrame - timeBeginFrame}` )
        window.requestAnimationFrame( ( timestamp: number ): void => { this.animate( timestamp ) } );
    }

    draw(): void {
        if ( this.ctx === null ) {
            return
        }

        this.ctx.clearRect( 0, 0, this.cancasSize.x, this.cancasSize.y )
        this.ctx.fillStyle = this.particlesColor.toStringHex()

        for ( let i: number = 0; i < this.particles.length; i++ ) {
            let p: Particle = this.particles[ i ]
            this.ctx.beginPath()
            p.draw( this.ctx )
            this.ctx.fill()
        }

        this.drawlines( 4, this.linesDistance2 )
    }

    stop(): void {
        this.bShouldStop = true
    }

    start(): void {
        this.initParticles()

        this.bShouldStop = false
        this.lastFrameTime = performance.now()
        this.animate( this.lastFrameTime )
    }


}


///@brief   Main class for the effect, use useEffect() to start registering the canvas and start them.
class ParticlesEngine {

    particlesBackgrounds: ParticleBackground[]

    constructor() {
        this.particlesBackgrounds = []
    }

    useEffect(): void {
        // Nothing.
    }

    onPageLoaded(): void {
        this.updateParticlesBackgrounds()
        this.start()
    }

    private updateParticlesBackgrounds(): void {
        this.stop()
        this.particlesBackgrounds = []

        document.querySelectorAll( '[data-particles]' ).forEach( ( element: Element ): void => {
            if ( element instanceof HTMLElement ) { this.particlesBackgrounds.push( new ParticleBackground( element ) ) }
        } )
    }

    start(): void {
        this.particlesBackgrounds.forEach( ( pb: ParticleBackground ): void => {
            pb.start()
        } )
    }

    stop(): void {
        this.particlesBackgrounds.forEach( ( pb: ParticleBackground ): void => {
            pb.stop()
        } )
    }

    setParticlesColor( color: Color ): void {
        this.particlesBackgrounds.forEach( ( pb: ParticleBackground ): void => {
            pb.setParticlesColor( color )
        } )
    }

    setLinesColor( color: Color ): void {
        this.particlesBackgrounds.forEach( ( pb: ParticleBackground ): void => {
            pb.setLinesColor( color )
        } )
    }

    setFriction( friction: number ): void {
        this.particlesBackgrounds.forEach( ( pb: ParticleBackground ): void => {
            pb.setFriction( friction )
        } )
    }

    setInitialVelocity( velocity: number ): void {
        this.particlesBackgrounds.forEach( ( pb: ParticleBackground ): void => {
            pb.setInitialVelocity( velocity )
        } )
    }

    setSpawnRate( rate: number ): void {
        this.particlesBackgrounds.forEach( ( pb: ParticleBackground ): void => {
            pb.setSpawnRate( rate )
        } )
    }

    setParticlesLife( life: number ): void {
        this.particlesBackgrounds.forEach( ( pb: ParticleBackground ): void => {
            pb.setParticlesLife( life )
        } )
    }

    setLinesDistance( distance: number ): void {
        this.particlesBackgrounds.forEach( ( pb: ParticleBackground ): void => {
            pb.setLinesDistance( distance )
        })
    }

    getParticlesBackgrounds(): ParticleBackground[] {
        return this.particlesBackgrounds
    }

}

export let particlesEngine: ParticlesEngine = new ParticlesEngine()