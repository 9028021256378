import { Color } from './Color.ts'

export enum ShadowType {
	Text = 0,
	BoxInside = 1,
	BoxOutside = 2,
	Drop = 3
}






export class ShadowObject {

	private x: number
	private y: number
	private elementWidth: number
	private elementHeight: number
	private depth: number
	private shadowType: ShadowType
	private shadowColor: Color
	private blendedShadowColor: Color
	private element: HTMLElement

	private bEnabled: boolean

	constructor( element: HTMLElement, depth: number, shadowType: ShadowType, shadowColor: Color = new Color( 0.0, 0.0, 0.0, 0.4 ) ) {
		this.element = element
		this.depth = depth
		this.shadowType = shadowType
		this.shadowColor = shadowColor
		this.bEnabled = true
		this.blendedShadowColor = shadowColor

		this.updateShadowColor()
		this.updatePosition()
	}

	setEnabled( bEnabled: boolean ) : void {
		this.bEnabled = bEnabled
	}

	getEnabled(): boolean {
		return this.bEnabled
	}

	///@brief	Fully traverse the parent hierarchy to retrieve the fully blended background color and blend it
	///			with the given shadow color to pre-compute the non transparent color for a nice effect.
	updateShadowColor(): void {

		if ( this.shadowColor.a > 0.9 ) {
			this.blendedShadowColor = new Color( this.shadowColor.r, this.shadowColor.g, this.shadowColor.b, 1.0 )
			return
		}

		let colorStack: Color[] = []

		let parent: HTMLElement | null = this.element

		if ( this.shadowType === ShadowType.BoxOutside || this.shadowType === ShadowType.Drop ) {
			parent = this.element.parentElement
		}

		while ( parent !== null ) {
			let backgroundColorStr: string = window.getComputedStyle(parent).getPropertyValue('background-color')
			if ( backgroundColorStr === undefined || backgroundColorStr.length === 0 ) {
				// No attribute background-color, skip and go to parent.
				parent = parent.parentElement
				continue
			}

			// Get the background Color.
			let parsedBackgroundColor: Color | null = Color.fromString( backgroundColorStr )
			if ( parsedBackgroundColor === null ) {
				// Unable to parse the background-color attribute, skip and go to parent.
				parent = parent.parentElement
				continue
			}

			if ( parsedBackgroundColor.a < 0.01 ) {
				// Fully transparent background, can be ignored.
				parent = parent.parentElement
				continue
			}

			colorStack.push( parsedBackgroundColor )

			if ( parsedBackgroundColor.a < 0.9 ) {
				// We need an opaque background to finish the blending, continue
				parent = parent.parentElement
				continue
			}

			// Finished retrieving the not transparent background color.
			break
		}

		if ( colorStack.length === 0 ) {
			// We have a problem, not founded any background color.
			this.blendedShadowColor = new Color( this.shadowColor.r, this.shadowColor.g, this.shadowColor.b, 1.0 )
			return;
		}

		// Start with the end and roll back blend all the colors.
		let backgroundColor: Color = colorStack[ colorStack.length - 1 ]
		for ( let i: number = colorStack.length - 2; i >= 0; i-- ) {
			backgroundColor = Color.alphaBlend( backgroundColor, colorStack[ i ] )
		}

		// Compute the final blended shadow color.
		this.blendedShadowColor = Color.alphaBlend( backgroundColor, this.shadowColor )
	}

	///@brief	Get the element middle position.
	updatePosition(): void {
		this.elementWidth = Math.max( this.element.clientWidth, 1.0 )
		this.elementHeight = Math.max( this.element.clientHeight, 1.0 )

		this.x = this.element.offsetLeft + this.elementWidth * 0.5
		this.y = this.element.offsetTop + this.elementHeight * 0.5
	}

	///@brief	Same as computeShadowWithPos but with enabled/disabled handling.
	updateShadowWithPos( lightX: number, lightY: number, lightHeight: number = 100.0 ): void {
		if ( this.bEnabled ) {
			this.computeShadowWithPos( lightX, lightY, lightHeight )
		}
	}

	///@brief	Same as computeShadowWithVec but with enabled/disabled handling.
	updateShadowWithVec( vecX: number, vecY: number, lightHeight: number ): void {
		if ( this.bEnabled ) {
			this.computeShadowWithVec( vecX, vecY, lightHeight )
		}
	}

	///@brief	Compute the shadow position using an absolute light position.
	private computeShadowWithPos( lightX: number, lightY: number, lightHeight: number = 100.0 ): void {
		let vecX: number = lightX - this.x
		let vecY: number = lightY - this.y

		this.computeShadowWithVec( vecX, vecY, lightHeight )
	}

	///@brief	Compute the shadow position using only a vector.
	private computeShadowWithVec( vecX: number, vecY: number, lightHeight: number ): void {

		// Compute the distance of the light from the object.
		let dist: number = Math.sqrt( vecX * vecX + vecY * vecY )

		// Compute the projected distance to limit the effect if too high.
		let projectedDist: number = dist / lightHeight



		let projectedRatioMax: number = Math.min( 5.0 / projectedDist, 1.0 )
		let projectedDistSafe: number = Math.min( projectedDist, 5.0 ) * this.depth

		// Compute the projected x/y.
		let projectedX: number = -vecX / lightHeight * this.depth * projectedRatioMax
		let projectedY: number = -vecY / lightHeight * this.depth * projectedRatioMax

		// Set some of the others parameters.
		let blurRadius: number = 0.5


		// Set the number of shadow to the projected distance for a nice raytraced shadow.
		let shadowCssStr: string = ''

		// Compute and set the final css property.
		if ( this.shadowType === ShadowType.Text ) {
			let nbShadow: number = projectedDistSafe
			let blendedShadowColorStr: string = this.blendedShadowColor.toStringRgb()

			for ( let i: number = 0.0; i < nbShadow; i++ ) {
				if ( i > 0 ) { shadowCssStr += ', ' }
				shadowCssStr += `${ blendedShadowColorStr } ${ projectedX * ( i + 1.0 ) / nbShadow }px ${ projectedY * ( i + 1.0 ) / nbShadow }px ${ blurRadius }px`
			}
			this.element.style.textShadow = shadowCssStr
		} else if ( this.shadowType === ShadowType.BoxInside ) {
			let nbShadow: number = 1.0
			let blendedShadowColorStr: string = nbShadow > 1.0 ? this.blendedShadowColor.toStringRgb() : this.shadowColor.toStringRgba()

			for ( let i: number = 0.0; i < nbShadow; i++ ) {
				if ( i > 0 ) { shadowCssStr += ', ' }
				shadowCssStr += `inset ${ blendedShadowColorStr } ${ projectedX * ( i + 1.0 ) / nbShadow }px ${ projectedY * ( i + 1.0 ) / nbShadow }px ${ blurRadius }px`
			}
			this.element.style.boxShadow = shadowCssStr
		} else if ( this.shadowType === ShadowType.BoxOutside ) {

			let nbShadow: number = Math.max( projectedDistSafe / Math.min( this.elementWidth, this.elementHeight ), 1.0 )
			let blendedShadowColorStr: string = nbShadow > 1.0 ? this.blendedShadowColor.toStringRgb() : this.shadowColor.toStringRgba()

			for ( let i: number = 0.0; i < nbShadow; i++ ) {
				if ( i > 0 ) { shadowCssStr += ', ' }
				shadowCssStr += `${ blendedShadowColorStr } ${ projectedX * ( i + 1.0 ) / nbShadow }px ${ projectedY * ( i + 1.0 ) / nbShadow }px ${ blurRadius }px`
			}
			this.element.style.boxShadow = shadowCssStr
		} else if ( this.shadowType === ShadowType.Drop ) {
			// let nbShadow: number = 1.0
			// let blendedShadowColorStr: string = nbShadow > 1.0 ? this.blendedShadowColor.toRgbString() : this.shadowColor.toRgbaString()
			let nbShadow: number = Math.max( projectedDistSafe, 1.0 )
			let blendedShadowColorStr: string = this.blendedShadowColor.toStringRgba()

			projectedX = projectedX * 0.2
			projectedY = projectedY * 0.2
			blurRadius = 0.0

			for ( let i: number = 0.0; i < nbShadow; i++ ) {
				if ( i > 0 ) { shadowCssStr += ' ' }
				shadowCssStr += `drop-shadow(${ blendedShadowColorStr } ${ projectedX * ( i + 1.0 ) / nbShadow }px ${ projectedY * ( i + 1.0 ) / nbShadow }px ${ blurRadius }px)`
			}
			this.element.style.filter = shadowCssStr
		}
	}


	removeShadow(): void {
		if ( this.shadowType === ShadowType.Text ) {
			this.element.style.textShadow = ''
		} else if ( this.shadowType === ShadowType.BoxInside ) {
			this.element.style.boxShadow = ''
		} else if ( this.shadowType === ShadowType.BoxOutside ) {
			this.element.style.boxShadow = ''
		} else if ( this.shadowType === ShadowType.Drop ) {
			this.element.style.filter = ''
		}
	}


}

export enum ProjectionType {
	Orthographic = 0,
	Mouse = 1
}


export class ShadowEngine {

	private projectionType: ProjectionType

	private shadowElementList: ShadowObject[]

	private lightZ: number

	private lastMouseX: number | null
	private lastMouseY: number | null

	private orthoX: number
	private orthoY: number

	constructor() {
		this.projectionType = ProjectionType.Mouse
		this.orthoX = 0.0
		this.orthoY = 0.0
		this.shadowElementList = []

		this.lightZ = 200.0
		this.lastMouseX = null
		this.lastMouseY = null
	}

	useEffect( projectionType: ProjectionType, orthoX: number = -700.0, orthoY: number = 400.0, lightZ : number = 200.0 ): void {
		this.projectionType = projectionType
		this.orthoX = orthoX
		this.orthoY = orthoY
		this.lightZ = lightZ

		this.registerEvents()
	}

	setLightZ( lightZ: number ): void {
		this.lightZ = lightZ

		this.updateAllShadowPosition()
	}


	updateAllShadowColor(): void {
		this.shadowElementList.forEach( ( shadowElement: ShadowObject ): void => {
			shadowElement.updateShadowColor()
		} )
	}

	getLightHeight(): number {
		return this.lightZ
	}

	updateShadowPosition( shadowElement: ShadowObject, mouseEvent: MouseEvent ): void {

		this.lastMouseX = mouseEvent.pageX
		this.lastMouseY = mouseEvent.pageY

		shadowElement.updateShadowWithPos( this.lastMouseX, this.lastMouseY, this.lightZ )
	}

	updateAllShadowPosition(): void {

		this.shadowElementList.forEach( ( shadowElement: ShadowObject ): void => {
			if ( this.projectionType === ProjectionType.Mouse ) {
				if ( this.lastMouseX === null || this.lastMouseY === null ) {
					shadowElement.updateShadowWithVec( this.orthoX, this.orthoY, this.lightZ )
					return
				}
				shadowElement.updateShadowWithPos( this.lastMouseX, this.lastMouseY, this.lightZ )
			} else {
				shadowElement.updateShadowWithVec( this.orthoX, this.orthoY, this.lightZ )
			}
		} )

	}

	onPageChange(): void {
		this.updateElementList()
	}

	updateElementList(): void {

		this.shadowElementList.forEach( ( shadowElement: ShadowObject ): void => {
			shadowElement.removeShadow()
		} )

		this.shadowElementList = []
		this.constructShadowElementList()
		this.updateAllShadowPosition()
	}

	handleEvent( event: MouseEvent ): void {
		switch ( event.type ) {
			case 'load':
				this.updateElementList()
				break
		}
	}

	registerEvents(): void {
		window.removeEventListener('load', this)
		window.addEventListener('load', this)
	}

	constructShadowElementList(): void {

		this.shadowElementList = []

		document.querySelectorAll( '.text-shadow-4' ).forEach( ( element: Element ): void => {
			if ( element instanceof HTMLElement ) { this.shadowElementList.push( new ShadowObject( element, 4.0, ShadowType.Text, new Color( 0.0, 0.0, 0.0, 0.3 ) ) ) }
		} )
		document.querySelectorAll( '.text-shadow-3' ).forEach( ( element: Element ): void => {
			if ( element instanceof HTMLElement ) { this.shadowElementList.push( new ShadowObject( element, 3.0, ShadowType.Text, new Color( 0.0, 0.0, 0.0, 0.3 ) ) ) }
		} )
		document.querySelectorAll( '.text-shadow-2' ).forEach( ( element: Element ): void => {
			if ( element instanceof HTMLElement ) { this.shadowElementList.push( new ShadowObject( element, 2.0, ShadowType.Text, new Color( 0.0, 0.0, 0.0, 0.3 ) ) ) }
		} )
		document.querySelectorAll( '.text-shadow-1' ).forEach( ( element: Element ): void => {
			if ( element instanceof HTMLElement ) { this.shadowElementList.push( new ShadowObject( element, 1.0, ShadowType.Text, new Color( 0.0, 0.0, 0.0, 0.3 ) ) ) }
		} )
		document.querySelectorAll( '.box-shadow-inside-1' ).forEach( ( element: Element ): void => {
			if ( element instanceof HTMLElement ) { this.shadowElementList.push( new ShadowObject( element, 1.0, ShadowType.BoxInside, new Color( 0.0, 0.0, 0.0, 0.3 ) ) ) }
		} )
		document.querySelectorAll( '.box-shadow-inside-2' ).forEach( ( element: Element ): void => {
			if ( element instanceof HTMLElement ) { this.shadowElementList.push( new ShadowObject( element, 2.0, ShadowType.BoxInside, new Color( 0.0, 0.0, 0.0, 0.3 ) ) ) }
		} )
		document.querySelectorAll( '.box-shadow-outside-1' ).forEach( ( element: Element ): void => {
			if ( element instanceof HTMLElement ) { this.shadowElementList.push( new ShadowObject( element, 1.0, ShadowType.BoxOutside, new Color( 0.0, 0.0, 0.0, 0.3 ) ) ) }
		} )
		document.querySelectorAll( '.box-shadow-outside-2' ).forEach( ( element: Element ): void => {
			if ( element instanceof HTMLElement ) { this.shadowElementList.push( new ShadowObject( element, 2.0, ShadowType.BoxOutside, new Color( 0.0, 0.0, 0.0, 0.3 ) ) ) }
		} )
		document.querySelectorAll( '.drop-shadow-2' ).forEach( ( element: Element ): void => {
			if ( element instanceof HTMLElement ) { this.shadowElementList.push( new ShadowObject( element, 2.0, ShadowType.Drop, new Color( 0.0, 0.0, 0.0, 0.3 ) ) ) }
		} )

	}



}

export let shadowEngine: ShadowEngine = new ShadowEngine()



