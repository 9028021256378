import React from 'react';
import { Link, Location } from 'react-router-dom'
import { useLocation } from 'react-router-dom';

import { shadowEngine, ProjectionType } from './Shadow.ts'

import './Header.css';


function MenuItem( { title, selected, to }: { title: string, selected: string, to: string } ): React.JSX.Element {

	let className: string = selected === "true" ? 'menuItemSelected text-shadow-2 box-shadow-inside-1' : 'menuItem'
	let selectorClassName: string = selected === "true" ? 'selector box-shadow-outside-2' : 'selector'

	return (
		<Link to={ to } >
			<div className={ className } >
				{ title }
				<div className={ selectorClassName }></div>
			</div>
		</Link>
	);
}

function Menu(): React.JSX.Element {

	let currentLocation: Location = useLocation()

	return (
		<div className="menu">
			<MenuItem title="Hello" selected={ currentLocation.pathname === '/' ? 'true' : 'false' } to="/"></MenuItem>
			<MenuItem title="Work" selected={ currentLocation.pathname === '/works' ? 'true' : 'false' } to="/works"></MenuItem>
		</div>
	);
}

// function setFavicon( iconPath: string ): void {
// 	let e: Element | null = document.querySelector( "link[rel~='icon']" );
// 	let link: HTMLLinkElement | null = e ? ( e as HTMLLinkElement ) : null;
// 	if ( link === null ) {
// 		link = document.createElement( 'link' );
// 		link.rel = 'icon';
// 		document.getElementsByTagName( 'head' )[ 0 ].appendChild( link );
// 	}
// 	link.href = require( iconPath );
// }


export function Header(): React.JSX.Element {


	let currentLocation: Location = useLocation()

	React.useEffect( (): void => {
		shadowEngine.onPageChange()
	}, [ currentLocation ] );

	React.useEffect( (): void => {
		shadowEngine.useEffect( ProjectionType.Orthographic, -700.0, 400.0, 200.0 )
	}, [] );

	return (
		<header>
			<div className="mainLogo logo-CG" />
			<div className="title">
				<span className="title text-shadow-4">Clément G.</span><br />
				<span className="description text-shadow-2">3D Developer & Data Scientist</span>
			</div>
			<div className="spacer"></div>
			<Menu></Menu>
			<div className="logos" >
				<a href="https://www.linkedin.com/in/cl%C3%A9ment-gerber-6ba699128/"><div className="logo logo-LinkedIn">LinkedIn</div></a>
				<a href="https://www.artstation.com/oriode"><div className="logo logo-ArtStation">ArtStation</div></a>
			</div>
		</header>
	);
}