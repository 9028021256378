import React from 'react';
import './Footer.css';

export function Footer(): React.JSX.Element {
    return ( <div className="footer box-shadow-inside-2">
        <div className="container" >
            <div className="left">
                Copyright Clément Gerber 2024 ©.<br />
                Website developed with <a className="react" href="https://react.dev/">React</a> in <a className="typescript" href="https://www.typescriptlang.org/">Typescript</a>.<br />
                This site is fully responsive. Yeah!
            </div>
            <div className="right">
                
                <b>Contact:</b>&nbsp;
                <a href="https://www.linkedin.com/in/cl%C3%A9ment-gerber-6ba699128/">LinkedIn <div className="image logo logo-LinkedIn" /></a>,&nbsp;&nbsp;
                <a href="https://www.artstation.com/oriode" >ArtStation <div className="image logo logo-ArtStation" /></a>,&nbsp;&nbsp;
                <a href="https://github.com/Oriode" >GitHub <div className="image logo logo-GitHub" /></a>
            </div>
        </div>
    </div> )
}