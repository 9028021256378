import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import { Header } from './Header.tsx'
import { Footer } from './Footer.tsx'
import { Hello } from './Hello.tsx'
import { Works } from './Works.tsx'

import './App.css';


function App() : React.JSX.Element {
	return (
		<div className="App">
			<Router>
				<div className="separator box-shadow-outside-1"></div>
				<Header />
				<div className="separator box-shadow-outside-1"></div>
				<Routes>
					<Route path="/" element={<Hello />} />
					<Route path="/works" element={<Works />} />
				</Routes>
				<Footer />
			</Router>
		</div>
	);
}

export default App;
