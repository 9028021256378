// https://gist.github.com/LucHighwalker/44f077cb3877bad361296eb24c316942

export class Color {
	static readonly _contrastFactor: number = 50.0;
	static readonly _rBrightVal: number = 0.21;
	static readonly _gBrightVal: number = 0.72;
	static readonly _bBrightVal: number = 0.07;

	static readonly _fromHexRegEx: RegExp = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{0,2})$/i;
	static readonly _fromRGBAStringRegEx: RegExp = /^rgba *\( *([0-9]+\.?[0-9]*) *, *([0-9]+\.?[0-9]*) *, *([0-9]+\.?[0-9]*) *, *([0-9]+\.?[0-9]*) *\)$/i
	static readonly _fromRGBStringRegEx: RegExp = /^rgb *\( *([0-9]+\.?[0-9]*) *, *([0-9]+\.?[0-9]*) *, *([0-9]+\.?[0-9]*) *\)$/i

	static get random(): Color {
		return new Color( ...Color.randomRgb() );
	}
	static get transparent(): Color {
		return new Color( 0, 0, 0, 0 );
	}
	static get white(): Color {
		return new Color( 255, 255, 255 );
	}
	static get black(): Color {
		return new Color( 0, 0, 0 );
	}
	static get grey(): Color {
		return new Color( 210, 217, 216 );
	}
	static get lightGrey(): Color {
		return new Color( 244, 246, 246 );
	}
	static get darkGrey(): Color {
		return new Color( 148, 148, 148 );
	}
	static get red(): Color {
		return new Color( 255, 0, 0 );
	}
	static get green(): Color {
		return new Color( 0, 255, 0 );
	}
	static get blue(): Color {
		return new Color( 0, 0, 255 );
	}

	static get rdRed(): Color {
		return new Color( 221, 56, 91 );
	}
	static get rdLightRed(): Color {
		return new Color( 222, 114, 135 );
	}
	static get rdLighterRed(): Color {
		return new Color( 241, 218, 221 );
	}
	static get rdGrey(): Color {
		return new Color( 210, 217, 216 );
	}
	static get rdLightGrey(): Color {
		return new Color( 244, 246, 246 );
	}
	static get rdBlack(): Color {
		return new Color( 43, 54, 63 );
	}
	static get rdTeal(): Color {
		return new Color( 102, 188, 171 );
	}
	static get rdBlue(): Color {
		return new Color( 43, 54, 63 );
	}

	private hexVal: string;
	private strVal: string;
	private rgbVal: number[];
	private rgbaVal: number[];
	private invVal: number[];
	private pBrightVal: number;

	private rVal: number;
	private gVal: number;
	private bVal: number;
	private aVal: number;

	public get hex(): string {
		return this.hexVal;
	}
	public set hex( hex: string ) {
		this.setHex( hex );
	}

	public get str(): string {
		return this.strVal;
	}

	public get rgb(): number[] {
		return this.rgbVal;
	}
	public set rgb( rgb: number[] ) {
		this.setRgb( rgb[ 0 ] || 0, rgb[ 1 ] || 0, rgb[ 2 ] || 0, 1 );
	}
	public get rgba(): number[] {
		return this.rgbaVal;
	}
	public set rgba( rgba: number[] ) {
		this.setRgb( rgba[ 0 ] || 0, rgba[ 1 ] || 0, rgba[ 2 ] || 0, rgba[ 3 ] || 1 );
	}

	public get inverted(): number[] {
		return this.invVal;
	}
	public get brightness(): number {
		return this.pBrightVal;
	}

	public get r(): number {
		return this.rVal;
	}
	public set r( r: number ) {
		this.rVal = r;
		this.validate();
	}
	public get g(): number {
		return this.gVal;
	}
	public set g( g: number ) {
		this.gVal = g;
		this.validate();
	}
	public get b(): number {
		return this.bVal;
	}
	public set b( b: number ) {
		this.bVal = b;
		this.validate();
	}
	public get a(): number {
		return this.aVal;
	}
	public set a( a: number ) {
		this.aVal = a;
		this.validate();
	}

	static lerp( color1: Color, color2: Color, t: number ): Color {
		return new Color(
			( 1 - t ) * color1.r + t * color2.r,
			( 1 - t ) * color1.g + t * color2.g,
			( 1 - t ) * color1.b + t * color2.b,
			( 1 - t ) * color1.a + t * color2.a
		);
	}

	static alphaBlend( backgroundColor: Color, overlayColor: Color ): Color {
		let blendedColor: Color = new Color( 0.0, 0.0, 0.0, 0.0 )

		blendedColor.rVal = Math.round( overlayColor.r * overlayColor.a + backgroundColor.r * ( 1.0 - overlayColor.a ) )
		blendedColor.gVal = Math.round( overlayColor.g * overlayColor.a + backgroundColor.g * ( 1.0 - overlayColor.a ) )
		blendedColor.bVal = Math.round( overlayColor.b * overlayColor.a + backgroundColor.b * ( 1.0 - overlayColor.a ) )
		blendedColor.aVal = backgroundColor.a + ( 1.0 - backgroundColor.a ) * overlayColor.a

		return blendedColor
	}

	// tslint:disable: no-bitwise
	static rgbFromString( str: string ): [ number, number, number ] {
		const rgb: [ number, number, number ] = [ 0, 0, 0 ];
		if ( str.length === 0 ) {
			return rgb;
		}

		let hash: number = 0;
		for ( let i: number = 0; i < str.length; i++ ) {
			hash = str.charCodeAt( i ) + ( ( hash << 5 ) - hash );
			hash = hash & hash;
		}
		for ( let i: number = 0; i < 3; i++ ) {
			const value: number = ( hash >> ( i * 8 ) ) & 255;
			rgb[ i ] = value;
		}
		return rgb;
	}
	// tslint:enable: no-bitwise

	static hexFromString( str: string ): string {
		return Color.rgbToHex( ...Color.rgbFromString( str ) );
	}

	static hexToRgba( hex: string ): number[] {
		let result: RegExpExecArray | null = Color._fromHexRegEx.exec( hex );

		if ( result === null ) {
			throw new Error( 'Invalid hex value.' );
		}

		const alpha: number = parseInt( result[ 4 ] || 'ff', 16 ) / 255;

		return result ? [ parseInt( result[ 1 ], 16 ), parseInt( result[ 2 ] || '00', 16 ), parseInt( result[ 3 ] || '00', 16 ), alpha ] : [];
	}

	static rgbToHex( r: number, g: number, b: number, a: number = 1 ): string {
		return `#${ Color.numToHex( r ) }${ Color.numToHex( g ) }${ Color.numToHex( b ) }${ Color.numToHex( Math.floor( a * 255 ) ) }`;
	}

	setHexString( hexString: string ): boolean {
		let regExResult: RegExpExecArray | null = Color._fromHexRegEx.exec( hexString );

		if ( regExResult == null ) {
			return false
		}

		this.r = parseInt( regExResult[ 1 ], 16 )
		this.g = parseInt( regExResult[ 2 ] || '00', 16 )
		this.b = parseInt( regExResult[ 2 ] || '00', 16 )
		this.a = parseInt( regExResult[ 4 ] || 'ff', 16 ) / 255.0

		return true
	}

	static fromHexString( hexString: string ): Color | null {
		let newColor: Color = new Color()

		if ( !newColor.setHexString( hexString ) ) {
			return null
		}

		return newColor
	}

	setRgbaString( rgbaString: string ): boolean {

		let regExResult: RegExpExecArray | null = Color._fromRGBAStringRegEx.exec( rgbaString );

		if ( regExResult === null ) {
			return false
		}

		this.r = parseInt( regExResult[ 1 ] )
		this.g = parseInt( regExResult[ 2 ] )
		this.b = parseInt( regExResult[ 3 ] )
		this.a = Number( regExResult[ 4 ] )

		return true
	}

	static fromRgbaString( rgbaString: string ): Color | null {
		let newColor: Color = new Color()
		if ( !newColor.setRgbaString( rgbaString ) ) {
			return null
		}
		return newColor
	}

	setRgbString( rgbString: string ): boolean {
		let regExResult: RegExpExecArray | null = Color._fromRGBStringRegEx.exec( rgbString );

		if ( regExResult === null ) {
			return false
		}

		this.r = parseInt( regExResult[ 1 ] )
		this.g = parseInt( regExResult[ 2 ] )
		this.b = parseInt( regExResult[ 3 ] )
		this.a = 1.0

		return true
	}

	static fromRgbString( str: string ): Color | null {
		let newColor: Color = new Color()
		if ( !newColor.setRgbString( str ) ) {
			return null
		}
		return newColor
	}

	setString( str: string ): boolean {
		if ( str[ 0 ] === '#' ) {
			return this.setHexString( str )
		}
		if ( this.setRgbaString( str ) ) {
			return true
		}
		return this.setRgbString( str )

	}

	static fromString( str: string ): Color | null {
		let newColor: Color = new Color()
		if ( !newColor.setString( str ) ) {
			return null
		}
		return newColor
	}

	static randomRgb(): [ number, number, number ] {
		const r: number = Math.floor( Math.random() * 255 );
		const g: number = Math.floor( Math.random() * 255 );
		const b: number = Math.floor( Math.random() * 255 );
		return [ r, g, b ];
	}

	static randomRgba(): [ number, number, number, number ] {
		const r: number = Math.floor( Math.random() * 255 );
		const g: number = Math.floor( Math.random() * 255 );
		const b: number = Math.floor( Math.random() * 255 );
		const a: number = Math.floor( Math.random() * 100 ) / 100;
		return [ r, g, b, a ];
	}

	static randomHex(): string {
		const rgb: [ number, number, number ] = Color.randomRgb();
		return Color.rgbToHex( rgb[ 0 ], rgb[ 1 ], rgb[ 2 ] );
	}

	private static numToHex( num: number ): string {
		const hex: string = num.toString( 16 );
		return hex.length === 1 ? `0${ hex }` : hex;
	}

	constructor( r: number = 0, g: number = 0, b: number = 0, a: number = 1 ) {
		this.setRgb( r, g, b, a );
	}

	private validate(): void {
		this.clamp();

		this.hexVal = this.toStringHex();
		this.strVal = this.toString();

		this.rgbVal = [ this.rVal, this.gVal, this.bVal, this.aVal ];
		this.invVal = [ 255 - this.rVal, 255 - this.gVal, 255 - this.bVal ];
		this.pBrightVal = ( Color._rBrightVal * this.rVal + Color._gBrightVal * this.gVal + Color._bBrightVal * this.bVal ) / 3;
	}

	private clamp(): void {
		for ( const val of [ 'rVal', 'gVal', 'bVal' ] ) {
			this[ val ] = Math.floor( this[ val ] );
			if ( this[ val ] > 255 ) {
				this[ val ] = 255;
			} else if ( this[ val ] < 0 ) {
				this[ val ] = 0;
			}
		}

		if ( this.aVal > 1 ) {
			this.aVal = 1;
		} else if ( this.aVal < 0 ) {
			this.aVal = 0;
		}
	}

	public setHex( hex: string ): void {
		const rgba: number[] = Color.hexToRgba( hex );
		this.rVal = rgba[ 0 ] || 0;
		this.gVal = rgba[ 1 ] || 0;
		this.bVal = rgba[ 2 ] || 0;
		this.aVal = rgba[ 3 ] || 1;

		this.validate();
	}

	public setRgb( r: number, g: number, b: number, a: number = 1 ): void {
		this.rVal = r;
		this.gVal = g;
		this.bVal = b;
		this.aVal = a;

		this.validate();
	}

	public invert(): void {
		this.rgb = this.invVal;
	}

	public toStringRgb(): string {
		return `rgb(${ this.rVal }, ${ this.gVal }, ${ this.bVal })`;
	}

	public toStringRgba(): string {
		return `rgba(${ this.rVal }, ${ this.gVal }, ${ this.bVal }, ${ this.aVal })`;
	}

	public toStringHex(): string {
		return Color.rgbToHex( this.rVal, this.gVal, this.bVal, this.aVal );
	}

	public shouldContrast(): boolean {
		return this.pBrightVal < Color._contrastFactor;
	}

	public contrast(): Color {
		return this.shouldContrast() ? Color.rdLightGrey : Color.rdBlack;
	}

	public distanceFrom( color: Color ): number {
		const rDist: number = this.rVal - color.r;
		const gDist: number = this.gVal - color.g;
		const bDist: number = this.bVal - color.b;
		return ( rDist + gDist + bDist ) / 3;
	}

	public darken( amount: number ): Color {
		return new Color( this.r - amount, this.g - amount, this.b - amount );
	}

	public brighten( amount: number ): Color {
		return new Color( this.r + amount, this.g + amount, this.b + amount );
	}

	public transparentize( opacity: number ): Color {
		return new Color( this.r, this.g, this.b, opacity );
	}

	
}