import './ImageViewer.css'
import { Vec2 } from './Vec2.ts'

class Image {

	private pos: Vec2
	private size: Vec2
	private ratio: number

	private previousImage: Image | null
	private nextImage: Image | null

	private element: HTMLElement
	private imageElement: HTMLElement
	private descriptionElement: HTMLElement
	private marginContainer: HTMLElement

	private bAttached: boolean

	private beginX: number
	private endX: number

	constructor( targetImage: string, ratio: number, description: string, previousImage: Image | null ) {
		this.bAttached = false
		this.previousImage = previousImage
		this.nextImage = null
		this.pos = new Vec2( 0.0, 0.0 )
		this.size = new Vec2( 0.0, 0.0 )
		this.ratio = ratio

		this.setPrevious( previousImage )
		this.constructElement( targetImage, description )
	}

	constructElement( targetImage: string, description: string ): void {
		this.element = document.createElement( 'div' )
		this.element.setAttribute( 'class', 'imageContainer' )

		this.marginContainer = document.createElement( 'div' )

		this.imageElement = document.createElement( 'img' )
		this.imageElement.setAttribute( 'class', 'image' )
		this.imageElement.setAttribute( 'src', targetImage )
		this.imageElement.setAttribute( 'draggable', 'false' )

		this.descriptionElement = document.createElement( 'div' )
		this.descriptionElement.setAttribute( 'class', 'description' )
		this.descriptionElement.innerHTML = description

		this.marginContainer.append( this.imageElement )
		this.marginContainer.append( this.descriptionElement )

		this.element.append( this.marginContainer )
	}

	setNext( nextImage: Image | null ): void {
		this.nextImage = nextImage

		if ( this.nextImage !== null ) {
			this.nextImage.previousImage = this
		}
	}

	setPrevious( previousImage: Image | null ): void {
		this.previousImage = previousImage

		if ( this.previousImage !== null ) {
			this.previousImage.nextImage = this
		}
	}

	///@brief	Get the previous image in the list.
	getPrevious(): Image | null {
		return this.previousImage
	}

	///@brief	Get the next image in the list.
	getNext(): Image | null {
		return this.nextImage
	}

	computeImageSize( containerSize: Vec2 ): Vec2 {
		let imageSize: Vec2 = new Vec2( 0.0, 0.0 )

		let ratio: number = containerSize.x / containerSize.y

		// Adapt the sizing depending of the limited size.
		if ( ratio > this.ratio ) {
			imageSize.y = Math.min( containerSize.y * 0.9, containerSize.y - 0 )
			imageSize.x = imageSize.y * this.ratio
		} else {
			imageSize.x = containerSize.x * 0.9
			imageSize.y = imageSize.x / this.ratio
		}

		return imageSize
	}

	///@brief	Get the size of the image in pixels.
	getSize(): Vec2 {
		return this.size
	}

	///@brief	Get the position of the image in pixels from the top left corner of the container.
	getPos(): Vec2 {
		return this.pos
	}

	getElement(): HTMLElement {
		return this.element
	}

	getImageElement(): HTMLElement {
		return this.imageElement
	}

	attachToElement( element: HTMLElement | null ): void {

		if ( element === null ) {

			let parentElement: HTMLElement | null = this.element.parentElement
			if ( parentElement !== null ) {
				parentElement.removeChild( this.element )
			}

			this.bAttached = false
			return
		}

		let currentParent: HTMLElement | null = this.element.parentElement ?? null

		if ( currentParent === element ) {
			return
		}

		if ( currentParent !== null ) {
			let parentElement: HTMLElement | null = this.element.parentElement
			if ( parentElement !== null ) {
				parentElement.removeChild( this.element )
			}
		}

		element.appendChild( this.element )
		this.bAttached = true

	}

	///@brief	Find the image that contains the given x position.
	findImage( x: number ): Image | null {
		if ( x < this.beginX ) {
			return this.findPrevious( x )
		} else if ( x > this.endX ) {
			return this.findNext( x )
		}
		return this
	}

	private findPrevious( x: number ): Image | null {
		let i: Image | null = this

		while ( true ) {
			let previousImage: Image | null = i.getPrevious()
			if ( previousImage !== null ) {
				if ( x < i.beginX ) {

					i = previousImage
					continue
				}
			}
			return i
		}
	}

	private findNext( x: number ): Image | null {
		let i: Image | null = this

		while ( true ) {
			let nextImage: Image | null = i.getNext()
			if ( nextImage !== null ) {
				if ( x > i.endX ) {
					i = nextImage
					continue
				}
			}
			return i
		}
	}

	///@brief	Update the position of the image using the previous image position.
	///			Does not recompute the size.
	///@param	containerWidth	Width in pixels of the container element.
	///@param	containerHeight	Height in pixels of the container element.
	///@param	depth			Depth of the update, if 0, only the current image is updated, if 1, the next and previous images are updated.
	///@param	addedMarginX	Added margin in pixels to the right and left of the image.
	///@param	borderWidth		Width of the border in pixels.
	private updatePositionFromPrevious( containerSize: Vec2, depth: number, addedMarginX: number = 50.0, borderWidth: number = 2.0 ): void {

		if ( this.previousImage === null ) {
			return
		}

		this.pos.x = this.previousImage.getPos().x + this.previousImage.getSize().x + addedMarginX
		this.pos.y = 0.0

		this.beginX = this.pos.x - addedMarginX * 0.5
		this.endX = this.pos.x + this.size.x + addedMarginX * 0.5

		this.marginContainer.style.marginLeft = `${ addedMarginX - borderWidth * 2.0 }px`

		// Little hack to keep the image centered if this is the last one.
		if ( this.nextImage === null ) {
			let marginRight: number = ( containerSize.x - this.size.x ) * 0.5
			this.marginContainer.style.marginRight = `${ marginRight - borderWidth * 2.0 }px`
		}

		let next: Image | null = this.getNext()
		for ( let i: number = 0; i < depth && next !== null; i++ ) {
			next.updatePositionFromPrevious( containerSize, 0 )
			next = next.getNext()
		}
	}

	///@brief	Update the position of the image using the next image position.
	///			Does not recompute the size.
	///@param	containerWidth	Width in pixels of the container element.
	///@param	containerHeight	Height in pixels of the container element.
	///@param	depth			Depth of the update, if 0, only the current image is updated, if 1, the next and previous images are updated.
	///@param	addedMarginX	Added margin in pixels to the right and left of the image.
	///@param	borderWidth		Width of the border in pixels.
	private updatePositionFromNext( containerSize: Vec2, depth: number, addedMarginX: number = 50.0, borderWidth: number = 2.0 ): void {
		this.pos = new Vec2( 0.0, 0.0 )

		if ( this.nextImage === null ) {
			return
		}

		this.pos.x = this.nextImage.getPos().x - this.size.x - addedMarginX
		this.pos.y = 0.0

		this.beginX = this.pos.x - addedMarginX * 0.5
		this.endX = this.pos.x + this.size.x + addedMarginX * 0.5

		this.marginContainer.style.marginLeft = `${ addedMarginX - borderWidth * 2.0 }px`

		let prev: Image | null = this.getPrevious()
		for ( let i: number = 0; i < depth && prev !== null; i++ ) {
			prev.updatePositionFromNext( containerSize, 0 )
			prev = prev.getPrevious()
		}
	}

	///@brief	Update the position of the image to the center of the container.
	///			Does not recompute the size.
	///@param	containerWidth	Width in pixels of the container element.
	///@param	containerHeight	Height in pixels of the container element.
	///@param	depth	Depth of the update, if 0, only the current image is updated, if 1, the next and previous images are updated.
	updatePositionToFirst( containerSize: Vec2, depth: number, addedMarginX: number = 50.0, borderWidth: number = 2.0 ): void {

		this.pos.x = containerSize.x * 0.5 - this.size.x * 0.5
		this.pos.y = containerSize.y * 0.5 - this.size.y * 0.5

		this.beginX = 0.0
		this.endX = this.pos.x + this.size.x + addedMarginX * 0.5

		this.marginContainer.style.marginLeft = `${ this.pos.x }px`

		let next: Image | null = this.getNext()
		for ( let i: number = 0; i < depth && next !== null; i++ ) {
			next.updatePositionFromPrevious( containerSize, 0 )
			next = next.getNext()
		}

		let prev: Image | null = this.getPrevious()
		for ( let i: number = 0; i < depth && prev !== null; i++ ) {
			prev.updatePositionFromNext( containerSize, 0 )
			prev = prev.getPrevious()
		}
	}

	getFirst( bOnlyAttached: boolean ): Image {

		let currentImage: Image = this
		while ( true ) {

			let previousImage: Image | null = currentImage.getPrevious()
			if ( previousImage === null || ( bOnlyAttached && !previousImage.bAttached ) ) {
				break
			}

			currentImage = previousImage
		}

		return currentImage
	}

	///@brief	Update the size using the container size.
	///@param	containerWidth	Width in pixels of the container element.
	///@param	containerHeight	Height in pixels of the container element.
	///@param	depth	Depth of the update, if 0, only the current image is updated, if 1, the next and previous images are updated.
	updateSize( containerSize: Vec2, depth: number = 0 ): void {
		this.size = this.computeImageSize( containerSize )

		this.imageElement.style.width = `${ this.size.x }px`
		this.imageElement.style.height = `${ this.size.y }px`

		if ( depth === 0 ) {
			return
		}

		let next: Image | null = this.getNext()
		for ( let i: number = 0; i < depth && next !== null; i++ ) {
			next.updateSize( containerSize, 0 )
			next = next.getNext()
		}
		let prev: Image | null = this.getPrevious()
		for ( let i: number = 0; i < depth && prev !== null; i++ ) {
			prev.updateSize( containerSize, 0 )
			prev = prev.getPrevious()
		}
	}

	///@brief	Retrieve the list of images starting from this one to max depth.
	getImageList( depth: number ): Image[] {
		let list: Image[] = [ this ]

		if ( depth === 0 ) {
			return list
		}

		let next: Image | null = this.getNext()
		for ( let i: number = 0; i < depth && next !== null; i++ ) {
			list.push( next )
			next = next.getNext()
		}
		let prev: Image | null = this.getPrevious()
		for ( let i: number = 0; i < depth && prev !== null; i++ ) {
			list.push( prev )
			prev = prev.getPrevious()
		}

		return list
	}

}

class ImageViewer {

	private thumbnailList: Thumbnail[]

	private element: HTMLElement

	private currentImage: Image | null
	private targetImage : Image | null
	private firstImage: Image | null

	private animationTime: number

	private elementSize: Vec2
	private currentScroll: Vec2

	private endScrollTimeOut: ReturnType<typeof setTimeout>
	private closeTimeOut: ReturnType<typeof setTimeout>

	constructor() {
		this.endScrollTimeOut = setTimeout( (): void => { }, 0 )
		this.closeTimeOut = setTimeout( (): void => { }, 0 )
		this.thumbnailList = []
		this.animationTime = 300.0
		this.currentImage = null
		this.firstImage = null

		this.currentScroll = new Vec2( 0.0, 0.0 )
		this.elementSize = new Vec2( 0.0, 0.0 )
	}

	///@brief	Construct the imageViewer element.
	useEffect(): void {
		this.registerWindowEvents()
	}

	///@brief	Event to be called when a new page is loaded.
	onPageLoaded(): void {
		this.constructElement()
		this.constructThumbnailList()
	}

	private onLoad( e: Event ): void {
		this.constructElement()
		this.constructThumbnailList()
	}

	private onResize( e: Event ): void {
		this.updateSize()
		this.updateImagesPosition()
		if ( this.currentImage !== null ) {
			this.scrollToImage( this.currentImage, true )
		}
	}

	private onWheel( e: WheelEvent ): void {
		if ( this.currentImage === null ) {
			return
		}

		if ( e.deltaY < 0 ) {
			this.goToPreviousImage()
		} else {
			this.goToNextImage()
		}

		e.preventDefault()
	}

	private onScroll( e: Event ): void {

		if ( this.currentImage === null ) {
			return
		}

		this.currentScroll.x = this.element.scrollLeft

		let imageViewerCenterX: number = this.currentScroll.x + this.elementSize.x * 0.5
		let imageUnderCenter: Image | null = this.currentImage.findImage( imageViewerCenterX )

		if ( imageUnderCenter !== null ) {
			this.setCurrentImage( imageUnderCenter )
		}

		clearTimeout( this.endScrollTimeOut )
		this.endScrollTimeOut = setTimeout( (): void => {
			this.alignImageAfterScroll()
		}, 100 )
	}

	private onKeyDown( e: KeyboardEvent ): void {
		if ( this.currentImage === null ) {
			return
		}

		if ( e.key === 'ArrowLeft' ) {
			this.goToPreviousImage()
			e.stopPropagation()
			e.stopImmediatePropagation()
			e.preventDefault()
		}
		if ( e.key === 'ArrowRight' ) {
			this.goToNextImage()
			e.stopPropagation()
			e.stopImmediatePropagation()
			e.preventDefault()
		}
		if ( e.key === 'Escape' ) {
			this.close()
			e.stopPropagation()
		}

	}

	private onClick( e: MouseEvent ): void {
		this.close()
		e.stopPropagation()
	}

	onThumbnailClick( e: MouseEvent, thumbnail: Thumbnail ): void {
		this.viewImageFromThumbnail( thumbnail, this.thumbnailList )
	}

	onClickOnImage( e: MouseEvent, image: Image ): void {
		e.stopPropagation()

		if ( this.currentImage === image ) {
			this.close()
			return
		}

		this.goToImage( image )
	}

	handleEvent( e: Event ): void {
		switch ( e.type ) {
			case 'click':
				this.onClick( e as MouseEvent )
				break
			case 'scroll':
				this.onScroll( e )
				break
			case 'keydown':
				this.onKeyDown( e as KeyboardEvent )
				break
			case 'wheel':
				this.onWheel( e as WheelEvent )
				break
			case 'load':
				this.onLoad( e as Event )
				break
			case 'resize':
				this.onResize( e as Event )
				break
		}

	}

	private constructElement(): void {
		let imageViewerElement: HTMLElement | null = document.querySelector( '.imageViewer' )

		if ( imageViewerElement === null ) {
			// The imageViewer element seems not to exists, let's create it from raw instead.
			imageViewerElement = document.createElement( 'div' )
			imageViewerElement.setAttribute( 'class', 'imageViewer' )

			document.querySelector( 'body' )?.append( imageViewerElement )
		}

		this.element = imageViewerElement

		this.element.removeEventListener( 'click', this )
		this.element.addEventListener( 'click', this )

		this.element.removeEventListener( 'scroll', this )
		this.element.addEventListener( 'scroll', this )

		this.element.removeEventListener( 'keydown', this )
		this.element.addEventListener( 'keydown', this )

		this.element.removeEventListener( 'wheel', this )
		this.element.addEventListener( 'wheel', this )

		this.updateSize()
	}

	private constructThumbnailList(): void {

		this.thumbnailList.forEach( ( thumb: Thumbnail ): void => {
			thumb.getElement().removeEventListener( 'click', thumb )
			thumb.getElement().style.cursor = 'default'
		} )

		this.thumbnailList = []

		document.querySelectorAll( '.thumbnail' ).forEach( ( element: Element ): void => {
			if ( !( element instanceof HTMLElement ) ) {
				return
			}
			let newThumbnail: Thumbnail = new Thumbnail( this, element );
			let image: Image | null = newThumbnail.getImage()

			if ( image === null ) {
				return
			}

			newThumbnail.getElement().style.cursor = 'pointer'
			this.thumbnailList.push( newThumbnail )
		} )

	}

	private constructImageList( thumbnail: Thumbnail, thumbnailList: Thumbnail[] ): Image | null {

		while ( this.element.firstChild ) {
			this.element.removeChild( this.element.firstChild )
		}

		let currentSetName: string = thumbnail.getSet()
		let currentImage: Image | null = null
		this.firstImage = null

		let imageList: Image[] = []
		for ( let i: number = 0; i < thumbnailList.length; i++ ) {
			let t: Thumbnail = thumbnailList[ i ]

			// We only want to display the images of the current set.
			if ( t.getSet() !== currentSetName ) {
				continue
			}

			let image: Image | null = t.getImage()

			if ( image === null ) {
				continue
			}

			if ( this.firstImage === null ) {
				this.firstImage = t.getImage()
			}

			// As it is a chained list, we need to keep track of the previous image.
			let previousImage: Image | null = imageList.length > 0 ? imageList[ imageList.length - 1 ] : null
			image.setPrevious( previousImage )

			if ( t === thumbnail ) {
				currentImage = image
			}

			image.attachToElement( this.element )

			// Push the new image to the list.
			imageList.push( image )
		}

		return currentImage
	}

	private registerWindowEvents(): void {
		window.removeEventListener( 'resize', this )
		window.addEventListener( 'resize', this )
		window.removeEventListener( 'load', this )
		window.addEventListener( 'load', this )
		window.removeEventListener( 'keydown', this )
		window.addEventListener( 'keydown', this )
	}

	viewImageFromThumbnail( currentThumbnail: Thumbnail, thumbnailList: Thumbnail[] = [ currentThumbnail ] ): void {

		let newImage: Image | null = this.constructImageList( currentThumbnail, thumbnailList )

		if ( newImage === null ) {
			console.error( 'Could not find the image to display.' )
			return
		}

		this.open( newImage )
	}

	goToPreviousImage(): void {
		if ( this.targetImage === null ) {
			return
		}

		let previous: Image | null = this.targetImage.getPrevious()
		if ( previous === null ) {
			this.goToImage( this.targetImage )
			return
		}

		this.goToImage( previous )
	}

	goToNextImage(): void {
		if ( this.targetImage === null ) {
			return
		}

		let next: Image | null = this.targetImage.getNext()
		if ( next === null ) {
			this.goToImage( this.targetImage )
			return
		}

		this.goToImage( next )
	}

	///@brief	Update the size of the container element.
	///			Also update the size of the current images.
	private updateSize(): void {

		this.elementSize.x = window.innerWidth
		this.elementSize.y = window.innerHeight

		this.updateImagesSize()
	}

	///@brief	Update the size of the current images.
	private updateImagesSize(): void {

		if ( this.currentImage === null ) {
			return
		}

		this.currentImage.updateSize( this.elementSize, 1000 )
	}

	private updateImagesPosition(): void {

		if ( this.firstImage === null ) {
			return
		}

		this.firstImage.updatePositionToFirst( this.elementSize, 1000 )
	}

	private alignImageAfterScroll(): void {
		if ( this.currentImage === null ) {
			return
		}

		let imageViewerCenterX: number = this.currentScroll.x + this.elementSize.x * 0.5
		let imageUnderCenter: Image | null = this.currentImage.findImage( imageViewerCenterX )

		if ( imageUnderCenter !== null ) {
			this.scrollToImage( imageUnderCenter, true )
		}
	}

	private setCurrentImage( image: Image | null ): void {

		if ( this.currentImage === image ) {
			return
		}

		if ( this.currentImage !== null ) {
			this.currentImage.getElement().style.opacity = '0.2'
		}
		this.currentImage = image
		if ( this.currentImage !== null ) {
			this.currentImage.getElement().style.opacity = '1.0'
		} else {
			this.firstImage = null
		}
	}

	goToImage( image: Image ): void {
		this.scrollToImage( image, true )
	}

	scrollToImage( image: Image, bAnimate: boolean ): void {
		this.targetImage = image
		let scrollX: number = image.getPos().x + image.getSize().x * 0.5 - this.elementSize.x * 0.5

		if ( bAnimate ) {
			this.element.style.scrollBehavior = 'smooth'
		} else {
			this.element.style.scrollBehavior = 'auto'
		}

		this.element.scrollTo( scrollX, 0.0 )
	}

	open( image: Image ): void {
		if ( image === null || this.currentImage === image ) {
			this.close()
			return
		}

		clearTimeout( this.closeTimeOut )

		this.targetImage = image
		this.setCurrentImage( image )

		// Now let's compute the positions of every attached images.
		this.updateSize()
		this.updateImagesPosition()

		// Set the viewer to visible.
		this.element.style.visibility = 'visible'
		this.element.style.opacity = '1.0'

		if ( this.currentImage !== null ) {
			this.scrollToImage( this.currentImage, false )
		}
	}

	close(): void {
		this.targetImage = null
		this.setCurrentImage( null )

		this.element.style.opacity = '0.0'

		clearTimeout( this.closeTimeOut )
		this.closeTimeOut = setTimeout( (): void => { this.element.style.visibility = 'collapse' }, this.animationTime )
	}

}


class Thumbnail {

	private element: HTMLElement

	private targetImage: string | null
	private imageViewer: ImageViewer
	private image: Image | null

	constructor( imageViewer: ImageViewer, element: HTMLElement ) {
		this.imageViewer = imageViewer
		this.element = element
		this.targetImage = null
		this.image = null

		this.element.removeEventListener( 'click', this )
		this.element.addEventListener( 'click', this )

		this.computeTargetImage()
		this.constructImage()
	}

	getImage(): Image | null {
		return this.image
	}

	private constructImage(): void {
		if ( this.targetImage === null ) {
			this.image = null
			return
		}

		this.image = new Image( this.targetImage, this.getRatio(), this.getDescription(), null )

		// Assign the click event to the image.
		this.image.getElement().removeEventListener( 'click', this )
		this.image.getElement().addEventListener( 'click', this )
	}

	getDescription(): string {
		return this.element.getAttribute( 'data-description' ) ?? ''
	}

	handleEvent( e: Event ): void {
		switch ( e.type ) {
			case 'click':
				this.onClick( e as MouseEvent )
				break
		}

	}

	private onClick( e: MouseEvent ): void {
		if ( e.currentTarget === this.element ) {
			this.imageViewer.onThumbnailClick( e, this )
			return
		}
		if ( this.image !== null && e.currentTarget === this.image.getElement() ) {
			this.imageViewer.onClickOnImage( e, this.image )
			return
		}
	}

	getRatio(): number {
		return this.element.clientWidth / this.element.clientHeight
	}

	getTargetImage(): string | null {
		return this.targetImage
	}


	private computeTargetImage(): void {
		this.targetImage = null

		if ( this.element.nodeName === 'IMG' ) {
			let target: string | null = this.element.getAttribute( 'data-target' )
			if ( target !== null && target !== '' ) {
				this.targetImage = target
				return
			}
			let src: string | null = this.element.getAttribute( 'src' )
			if ( src !== null && src !== '' ) {
				this.targetImage = src
				return
			}
		}

		console.error( 'Could not find the target image.' )
	}

	getSet(): string {
		return this.element.getAttribute( 'data-set' ) ?? ''
	}

	getElement(): HTMLElement {
		return this.element
	}


}



export let imageViewer: ImageViewer = new ImageViewer()
