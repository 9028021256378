export class Vec2 {

    public x: number
    public y: number
    

    constructor(x: number, y: number) {
        this.x = x
        this.y = y
    }

    public add(v: Vec2): Vec2 {
        return new Vec2(this.x + v.x, this.y + v.y)
    }

    public sub(v: Vec2): Vec2 {
        return new Vec2(this.x - v.x, this.y - v.y)
    }

    public mult(s: number): Vec2 {
        return new Vec2(this.x * s, this.y * s)
    }

    public div(s: number): Vec2 {
        return new Vec2(this.x / s, this.y / s)
    }

    public dot(v: Vec2): number {
        return this.x * v.x + this.y * v.y
    }

    

}