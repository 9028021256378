import React from 'react';
import { Location } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


import { imageViewer } from './ImageViewer.ts';

import './Works.css';


export function Works(): React.JSX.Element {

    let currentLocation: Location = useLocation()

    React.useEffect( (): void => {

        setTimeout( () : void => {
            imageViewer.onPageLoaded()
        }, 500 )

    }, [ currentLocation ] );

    React.useEffect( (): void => {
        document.title = 'Clément Gerber | Works';
        imageViewer.useEffect()
    }, [] );

    return (
        <div className="works fade-in-0">
            <div className="pageTitle">
                <span className="title text-shadow-4">Works</span><br />
                <span className="description text-shadow-2">Projects I worked on.</span>
            </div>
            <div className="containers">
                <div className="container-1" >
                    <div className="background">
                        <div className="left">
                            <div className="spacer"></div>
                            <div className="logo logo-Fashion">
                                <img className="image" src={ require( './png/Logo.Imki.png' ) } alt="" width="128" />
                            </div>
                        </div>
                        <div className="right">
                            <div className="titleContainer">
                                <div className="workTitle text-shadow-2 test-shadow" >Design Studio, The Generative AI Fashion Viewer</div>
                                <div className="date text-shadow-1">2023 - 2024</div>
                            </div>

                            <div className="description" >The application showcase multiple mode and fashion models in a realtime environment to generate textile pattern and textures using <b>generative AI</b>.<br /><br />I developped this application using <b>Unreal Engine</b> and connected the AI through a <b>REST API</b>. The application offers multiple models, multiple environments and the ability to change the lights intensity and temperature.</div>
                            <div className="flags">
                                <div className="flag">Unreal Engine</div>
                                <div className="flag">C++</div>
                            </div>
                            <div className="images">
                                <img className="thumbnail image box-shadow-outside-1" src={ require( './png/designstudio_001_thumb.jpg' ) } data-target={ require( './png/designstudio_001.jpg' ) } data-description="Main view of the Unreal app while generating a new pattern. Prompt on the left side, history on the bottom and environment settings on the right" data-set="designstudio" alt="" />
                                <img className="thumbnail image box-shadow-outside-1" src={ require( './png/designstudio_002_thumb.jpg' ) } data-target={ require( './png/designstudio_002.jpg' ) } data-description="View a of a shirt" data-set="designstudio" alt="" />
                                <img className="thumbnail image box-shadow-outside-1" src={ require( './png/designstudio_003_thumb.jpg' ) } data-target={ require( './png/designstudio_003.jpg' ) } data-set="designstudio" data-description="View on another environment." alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-2 box-shadow-inside-2" >
                    <div className="background backgroundOdyssee">

                        <div className="left">
                            <div className="spacer"></div>
                            <div className="logo logo-Odyssee">
                                <img className="image" src={ require( './png/Logo.Imki.png' ) } alt="" width="128" /><br />
                            </div>
                        </div>
                        <div className="right ">
                            <div className="titleContainer">
                                <div className="workTitle text-shadow-2 test-shadow" >L'Odyssée Sonore, The immersive Show</div>
                                <div className="date text-shadow-1">Early 2023</div>
                            </div>
                            <div className="description" >
                            <img src={ require( './png/odyssee_ces_award.png' ) } alt="CES Award" height="128" />

                                L'Odyssée Sonore is an immersive show taking place in the Romanian Theater in the city of <b>Orange</b>.<br />I worked on the unique technology offering the viewer an immersive 3D audio while moving through the scene.<br />Every spectator is equipped with a smartphone and a pair of headphones.<br /><br />I developpent a total of 3 applications all using <b>Unreal Engine</b>, the spectator android application, the server receiving all the clients data and a monitoring app to view in realtime every spectator position, batery state, wifi quality...<br />

                            </div>
                            <div className="flags">
                                <div className="flag">Unreal Engine</div>
                                <div className="flag">C++</div>
                                <div className="flag">Android</div>
                            </div>
                            <div className="images">
                                <img className="thumbnail image box-shadow-outside-1" src={ require( './png/odyssee_commercial_001_thumb.jpg' ) } data-target={ require( './png/odyssee_commercial_001.jpg' ) } data-set="odyssee" data-description="Photo during the show." alt="" />
                                <img className="thumbnail image box-shadow-outside-1" src={ require( './png/odyssee_commercial_002_thumb.jpg' ) } data-target={ require( './png/odyssee_commercial_002.jpg' ) } data-set="odyssee" data-description="Photo during the show." alt="" />
                                <img className="thumbnail image box-shadow-outside-1" src={ require( './png/odyssee_commercial_003_thumb.jpg' ) } data-target={ require( './png/odyssee_commercial_003.jpg' ) } data-set="odyssee" data-description="Photo during the show." alt="" />

                                <img className="thumbnail image box-shadow-outside-1" src={ require( './png/odyssee_minimap_001_thumb.jpg' ) } data-target={ require( './png/odyssee_minimap_001.jpg' ) } data-set="odyssee" data-description="View of the monitoring application while playing the show, showing every spectator location and rotation. While hovering a spectator, multiple informations where available, as the battery life, the connection quality and the quality of the synchronisation" alt="" />


                                <img className="thumbnail image box-shadow-outside-1" src={ require( './png/odyssee_app_001_thumb.jpg' ) } data-target={ require( './png/odyssee_app_001.jpg' ) } data-set="odyssee" data-description="View of the spectator application while the show is playing. Health icons and language switch button on the top." alt="" />
                                <img className="thumbnail image box-shadow-outside-1" src={ require( './png/odyssee_app_002_thumb.jpg' ) } data-target={ require( './png/odyssee_app_002.jpg' ) } data-set="odyssee" data-description="View of the spectator application in debug mode. Showing the 3D view of the theater and the current state informations." alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-1" >
                    <div className="background bitcoinBackground">

                        <div className="left">
                            <div className="spacer"></div>
                            <div className="logo logo-Bitcoin" />
                        </div>

                        <div className="right">
                            <div className="titleContainer">
                                <div className="workTitle text-shadow-2 test-shadow" >Stock Analysis with Deep Neural Networks</div>
                                <div className="date text-shadow-1">2021 - 2022</div>
                            </div>
                            <div className="description" >I worked on time series predictions using <b>deep neural networks</b> and advanced statistical tools.<br /><br />Complete developpement of a trading strategy simulation platform in <b>C++</b> & <b>Python</b> capable of simulating broker orders and the evolution of a portfolio.<br />Implementing many stock analysis indicators (RSI, EMA, MACD, ATR, CCI...).<br />Auto-optimizing strategies using a clustered <b>Monte Carlo</b> method over multiple indexes at the same time.<br />Analysis of time series using <b>dense neural network</b>, <b>LSTM</b> and <b>Causal CNN</b>.<br />Optimising using supervised and non-supervised agents.
                            </div>
                            <div className="flags">
                                <div className="flag">C++</div>
                                <div className="flag">Python</div>
                                <div className="flag">PyTorch</div>
                                <div className="flag">Deep Neural Networks</div>
                            </div>
                            <div className="images">

                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-2 box-shadow-inside-2" >
                    <div className="background backgroundAriane">
                        <div className="left">
                            <div className="spacer"></div>
                            <div className="logo logo-Ariane6">
                                <img className="image" src={ require( './png/Logo.ArianeGroup.png' ) } alt="" width="128" /><br />
                            </div>
                        </div>
                        <div className="right">
                            <div className="titleContainer">
                                <div className="workTitle text-shadow-2 test-shadow" >Ariane 6 Monitoring & Control Software Developpement</div>
                                <div className="date text-shadow-1">2018-2020</div>
                            </div>
                            <div className="description" >I worked with the <b>Ariane 6</b> qualification team to add new features to the monitoring and control software in <b>Java RCP</b>.<br />I developped a unique application solutions to visualize the test sequence progression, monitor logs and easily view each sequence completion and output. This time as a web service in Python on the <b>Django framework</b>.<br />
                            </div>
                            <div className="flags">
                                <div className="flag">Java RCP</div>
                                <div className="flag">Python</div>
                                <div className="flag">Django</div>
                            </div>
                            <div className="images">

                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-1" >
                    <div className="background backgroundUnreal">

                        <div className="left">
                            <div className="spacer"></div>
                            <div className="logo logo-Unreal">
                            </div>
                        </div>
                        <div className="right">
                            <div className="titleContainer">
                                <div className="workTitle text-shadow-2 test-shadow" >Unreal Engine RPG Game</div>
                                <div className="date text-shadow-1">2017 - 2018</div>
                            </div>
                            <div className="description" ><b>Unreal Engine 4</b> RPG/Tower Defense video game.<br />Complete modeling/animation and texturing using <b>Blender</b> & <b>Adobe Painter/Designer</b>.<br /><b>C++</b> Game Logic<br />
                                <ul>
                                    <li><b>Spells</b> : Cast time, projectiles and area of effects.</li>
                                    <li><b>Items</b> : Procedurals generation with multiple parts/stats</li>
                                    <li><b>Damage</b> : Elementals damage with status procs</li>
                                </ul>
                                <i>This project was co-developped with a friend, now discontinued as too ambitious... (sadly)</i>
                            </div>
                            <div className="flags">
                                <div className="flag">C++</div>
                                <div className="flag">Unreal Engine</div>
                                <div className="flag">Blender</div>
                                <div className="flag">Substance Painter</div>
                            </div>
                            <div className="images">
                                <img className="thumbnail image box-shadow-outside-1" src={ require( './png/ue4game_001_thumb.jpg' ) } data-target={ require( './png/ue4game_001.jpg' ) } data-set="uegame" data-description="Screenshot of the first map of the game, representing a small little medieval town." alt="" />
                                <img className="thumbnail image box-shadow-outside-1" src={ require( './png/ue4game_002_thumb.jpg' ) } data-target={ require( './png/ue4game_002.jpg' ) } data-set="uegame" data-description="Screenshot in developpement of the game, showcasing the mage character." alt="" />
                                <img className="thumbnail image box-shadow-outside-1" src={ require( './png/ue4game_003_thumb.jpg' ) } data-target={ require( './png/ue4game_003.jpg' ) } data-set="uegame" data-description="Screenshot inside the inn." alt="" />

                                <img className="thumbnail image box-shadow-outside-1" src={ require( './png/ue4game_ui_001_thumb.jpg' ) } data-target={ require( './png/ue4game_ui_001.jpg' ) } data-set="uegame" data-description="Screenshot of the user interface." alt="" />
                                <img className="thumbnail image box-shadow-outside-1" src={ require( './png/ue4game_ui_002_thumb.jpg' ) } data-target={ require( './png/ue4game_ui_002.jpg' ) } data-set="uegame" data-description="Screenshot of the user interface." alt="" />
                                <img className="thumbnail image box-shadow-outside-1" src={ require( './png/ue4game_ui_003_thumb.jpg' ) } data-target={ require( './png/ue4game_ui_003.jpg' ) } data-set="uegame" data-description="Screenshot of the user interface." alt="" />
                                <img className="thumbnail image box-shadow-outside-1" src={ require( './png/ue4game_concept_001_thumb.jpg' ) } data-target={ require( './png/ue4game_concept_001.jpg' ) } data-set="uegame" data-description="Concept image of a grimoire, the main weapon of the mage character. Modelized on Blender and textured with Adobe Painter." alt="" />
                                <img className="thumbnail image box-shadow-outside-1" src={ require( './png/ue4game_concept_002_thumb.jpg' ) } data-target={ require( './png/ue4game_concept_002.jpg' ) } data-set="uegame" data-description="Concept image of a little house, to be present in the first map of the game. Modelized on Blender and textured with Adobe Painter." alt="" />
                                <img className="thumbnail image box-shadow-outside-1" src={ require( './png/ue4game_concept_003_thumb.jpg' ) } data-target={ require( './png/ue4game_concept_003.jpg' ) } data-set="uegame" data-description="Concept image of an automatic crossbow, the main weapon of the hunter character. Modelized on Blender and textured with Adobe Painter." alt="" />

                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-2 box-shadow-inside-2" >
                    <div className="background backgroundOpenGL">
                        <div className="left">
                            <div className="spacer"></div>
                            <div className="logo logo-OpenGL"></div>
                        </div>
                        <div className="right">
                            <div className="titleContainer">
                                <div className="workTitle text-shadow-2 test-shadow" >OpenGL 3D Engine</div>
                                <div className="date text-shadow-1">2013-2014</div>
                            </div>
                            <div className="description" >Homemade 3D Engine using <b>C++</b> & <b>OpenGL 3.3+</b><br />
                                <ul>
                                    <li>Vertex and framebuffers</li>
                                    <li><b>Texturing</b> : 2D and Cube Mapping.</li>
                                    <li><b>Text</b> :  2D rasterization and effects (outline, shadow...).</li>
                                    <li><b>Lightning & shading</b> : spot, omnidirectional and directional.</li>
                                    <li><b>Shadows</b> : with Percentage Closer Filtering (PCF) & Parallel-Split Shadow Mapping (PSSM).</li>
                                    <li><b>Tessellation</b> : heightmap displacement and triangle PN smoothing.</li>
                                    <li><b>Postprocessing</b> : Screen Space Ambient Occlusion (SSAO) & Screen Space Indirect Lightning (SSIL), Bloom, Depth of Field, FXAA, God Rays</li>
                                    <li><b>Other</b> : frustum culling, z-ordering, LOD meshes generation.</li>
                                </ul>
                            </div>
                            <div className="flags">
                                <div className="flag">C++</div>
                                <div className="flag">OpenGL</div>
                            </div>
                            <div className="images">
                                <img className="thumbnail image box-shadow-outside-1" src={ require( './png/3dengine_001_thumb.jpg' ) } data-target={ require( './png/3dengine_001.jpg' ) } data-set="opengl" data-description="Screenshot of the engine, showcasing the triangle PN realtime tesselation in a wireframe rendering." alt="" />
                                <img className="thumbnail image box-shadow-outside-1" src={ require( './png/3dengine_002_thumb.jpg' ) } data-target={ require( './png/3dengine_002.jpg' ) } data-set="opengl" data-description="Screenshot of the engine, showcasing depth of field post processing and volumetric god rays from a point light." alt="" />
                                <img className="thumbnail image box-shadow-outside-1" src={ require( './png/3dengine_003_thumb.jpg' ) } data-target={ require( './png/3dengine_003.jpg' ) } data-set="opengl" data-description="Screenshot of the engine, showcasing volumetric god rays from a directionnal light." alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-1" >
                    <div className="background">
                        <div className="left">
                            <div className="spacer"></div>
                            <div className="logo logo-CPP"></div>
                        </div>
                        <div className="right">
                            <div className="titleContainer">
                                <div className="workTitle text-shadow-2 test-shadow" >Simple++, A fast and light C++ Library</div>
                                <div className="date text-shadow-1">Side Project Since 2015</div>
                            </div>
                            <div className="description" >
                                Simple++ is a <b>C++</b> library regrouping a lot of data structures and tools any project may need.<br />
                                The Project started a while ago and is still maintained as a backbone of any of my C++ project.<br /><br />
                                It regroup <b>data structures</b>, <b>mathematicals</b> tools, <b>networking</b>, <b>image processing</b>, <b>Drive IO</b>, <b>regex</b>, <b>XML</b>, <b>JSON</b>, <b>neural networks</b>...<br />
                                It's a huge C++ <b>header only</b> project complient with the <b>cpp17</b> standard.<br />
                                All the features are fully interoperable and thinked to be the easiest possible to use.<br /><br />
                                The philosophy behind was to regroup all the commonly needed tools for any project with the extreme performances of C++ while being as simple to use as any mondern scripted language.<br /><br />

                                <i>The Project can be founded on <a href="https://github.com/Oriode/Simplepp">GitHub</a>.</i>
                            </div>
                            <div className="flags">
                                <div className="flag">C++</div>
                            </div>
                            <div className="images">


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );

}

